import type { AppProps } from 'next/app'
import Script from 'next/script'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import '../app/styles/globals.sass'

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<QueryClientProvider client={queryClient}>
			<Script
				strategy="beforeInteractive"
				src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=default%2Ces2015%2Ces2016%2Ces2017%2Ces2018%2CResizeObserver"
			/>
			<Component {...pageProps} />
		</QueryClientProvider>
	)
}
export default MyApp
